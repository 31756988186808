import React, { Fragment } from 'react';

import css from './SectionSearchQuery.module.scss';

const AllCategoriesBox = ({ title, color, textAlign }) => {
  return (
    <div className={css.allCategoriesBox} style={{display: 'flex', justifyContent: textAlign, width:'100%'}}>
      <h2  style={{color: color}} >{title}</h2>
    </div>
  );
};

interface ICategoryCardProps {
  image: string;
  label: string;
  url: string;
  altText?: string;
}

const CategoryCard = ({
  image,
  label,
  url,
  altText,
  
}: ICategoryCardProps) => {
  return (
    <a href={url}>
      <div className={css.categoryCard}>
        <div className={css.imgWrapper}>
          <img src={image} alt={altText ?? 'Fave image'} />
        </div>
        <h3 className={css.label}>{label}</h3>
      </div>
    </a>
  );
};

interface IProps {
  data: {
    titleAlignment: string;
    childAlignment: string;
    categories: [any];
    header: string;
    style: any;
  };
}

const SectionSearchQuery = (props: IProps) => {
  return (
    <div className={css.sectionContainer} style={{
      backgroundSize: 'cover',
      backgroundImage: props.data.style?.image? `url(${props.data.style?.image})` : 'none' }}>
      <div className={css.sectionContent}>
        <div className={css.allCategoriesContainer}>
          <AllCategoriesBox textAlign={props.data?.titleAlignment ?? 'start'} color={props.data?.style?.color || '#632dff'}  title={props.data.header} />
        </div>
        <div className={css.categoriesLinksContainer} style={{justifyContent: props.data?.childAlignment ?? 'flex-start'}}>
          <div className={css.categoryCardsContainer} >
            { props.data?.categories && props.data.categories?.map(category => {
              return category ? (
                <Fragment key={category._key}>
                  <CategoryCard
                
                    image={category.image}
                    url={category.url}
                    label={category.title}
                  />
                </Fragment>
              ) : null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionSearchQuery;
