import React from 'react';

import css from './SectionMobileApp.module.scss';
import { ReactComponent as QrCode } from '../assets/images/qr_code.svg';
import appleDownload from '../assets/images/apple_download.png';
import googleDownload from '../assets/images/google_download.png';


interface IProps {
  data: {
image:string;
header:string;
text:string;
qrLabel: string;
  }
}

const SectionMobileApp = (props: IProps) => {


  return (
    <div className={css.sectionContainer}>
      <div className={css.sectionContent}>
        <div className={css.appInfoContainer}>
          <div className={css.appInfoWrapper}>
            <div className={css.qrCodeContainer}>
              <QrCode />
              <div className={css.label}>{props.data.qrLabel}</div>
            </div>
            <div className={css.infoBox}>
              <div className={css.infoCaption}>{props.data.header}</div>
              <div className={css.infoText}>{props.data.text}</div>
              <div className={css.downloadButtonsContainer}>
                <a href="https://apps.apple.com/us/app/fave-for-passionate-fans/id1558839284">
                  <img src={appleDownload} alt="Fave app Apple download button" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.faveforfans.fave&hl=en_US&gl=US">
                  <img src={googleDownload} alt="Fave app Google download button" />
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={css.appImageContainer}>
          <div className={css.imageWrapper}>
            <img src={props.data.image} alt="Fave mobile app" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionMobileApp;
