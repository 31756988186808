import { ListingCard } from '../../../components';
import css from './SectionFeaturedSquare.module.scss';
import { useSelector } from 'react-redux';
import { getListingsById } from '../../../ducks/marketplaceData.duck';
import BlockContent from '@sanity/block-content-to-react';

interface IFandomCardProps {
  caption: string;
  text: string;
  color: string;
  titleAlignment: string;
}

const ListingCardComp: any = ListingCard;

const SectionHeader = ({
  caption,
  text,
  color,
  titleAlignment,
}: IFandomCardProps) => {
  return (
    <div style={{width:'100%', display: 'flex', flexDirection: 'column', alignItems: titleAlignment, color: color}}>
      
      <h2 style={{ color: color }}>{caption}</h2>
 
      {text ? <BlockContent style={{color:color}} blocks={text} /> : null}
    </div>
  );
};

interface ISectionFandomProps {
  index: number;
  fandomListings?: [];
  data: {
    childAlignment: string;
    titleAlignment: string;
    style: {
      color: string;
      type: string;
      image: string;
    };
    header: string;
    text: string;
    products: [];
    cta: {
      text: string;
      url: string;
    };
  };
}
const SectionFeaturedSquare = (props: ISectionFandomProps) => {
  const { latestFandomListings, loadingInital } = useSelector(
    (state: any) => state.LandingPage
  );

  const cmsContent = {
    caption: props.data.header,
    text: props.data.text,
    btnText: props.data.cta?.text,
    btnUrl: props.data.cta?.url,
  };

  const currentFandomIds = props.fandomListings
    ? props.fandomListings
    : !loadingInital && latestFandomListings['cms'];
  const state = useSelector((state: any) => state);
  const listings = currentFandomIds
    ? getListingsById(state, currentFandomIds)
    : [];

  return (
    <div
      className={css.sectionContainer}
      style={{
        backgroundSize: 'cover',
        backgroundImage: props.data.style?.image
          ? `url(${props.data.style?.image})`
          : 'none',
      }}
    >
      <div className={css.sectionContent}>
        <div className={css.fandomCardsContainer}>
        <SectionHeader color={props.data?.style?.color || '#632dff'} titleAlignment={props.data?.titleAlignment || 'flex-start'} {...cmsContent} />
        </div>
        <div className={css.fandomListingsContainer}  style={{justifyContent: props.data?.childAlignment || 'flex-start'}}>
          {latestFandomListings && props.data?.products &&
            props.data.products.map((listing, index) => {
              const listingToShow = listings.find(l => {
                return l.id.uuid == listing;
              });

              return listingToShow ? (
                <div
                  className={css.listingCardWrapper}
                  key={listing + '_sq_' + index}
                >
                  <ListingCardComp
                    key={listing + '_sqa_' + index}
                    listing={listingToShow}
                  />
                </div>
              ) : (
                <></>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default SectionFeaturedSquare;
