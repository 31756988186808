import React, { Fragment } from 'react';

import css from './SectionTextImages.module.scss';
import BlockContent from '@sanity/block-content-to-react';
import { PrimaryButton, SecondaryButton } from '../../../components';

const AllCategoriesBox = ({ title, text, color, textAlign }) => {
  return (
    <div
      className={css.allCategoriesBox}
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: textAlign,
        width: '100%',
        color: color,
      }}
    >
      {title ? <h2 style={{ color: color }}>{title}</h2> : null}
      {text ? <BlockContent blocks={text} /> : null}
    </div>
  );
};

interface ICategoryCardProps {
  url: string;
  altText?: string;
  text: string;
  header: string;
  subheader: string;
  color: string;
  image: string;
  isReverse: boolean;
  cta: any;
}

const CategoryCard = ({
  image,
  url,
  altText,
  text,
  header,
  subheader,
  color,
  isReverse,
  cta,
}: ICategoryCardProps) => {
  return (
    <div
      className={css.categoryCard}
      style={{ flexDirection: isReverse ? 'row-reverse' : 'row' }}
    >
      <div className={css.imgWrapper}>
        <img src={image} alt={altText ?? 'Fave image'} />
      </div>
      { (header || subheader || text) &&
        <div className={css.textWrapper} style={{ color: color }}>
          {subheader && (
            <h5 style={{ color: color }} className={css.label}>
              {subheader}
            </h5>
          )}
          {header && (
            <h3 style={{ color: color }} className={css.label}>
              {header}
            </h3>
          )}
          {text ? <BlockContent blocks={text} /> : null}
          {cta && cta.isVisible ? (
            <a href={cta.url}>
              {cta.style == 'primary' && (
                <PrimaryButton>{cta.urlDescription}</PrimaryButton>
              )}
              {cta.style == 'secondary' && (
                <SecondaryButton>{cta.urlDescription}</SecondaryButton>
              )}
            </a>
          ) : null}
        </div>
      }
    </div>
  );
};

interface IProps {
  data: {
    titleAlignment: string;
    childAlignment: string;
    textPictures: [any];
    header: string;
    text: string;
    style: any;
  };
}

const SectionTextImages = (props: IProps) => {
  return (
    <div
      className={css.sectionContainer}
      style={{
        backgroundSize: 'cover',
        backgroundImage: props.data.style?.image
          ? `url(${props.data.style?.image})`
          : 'none',
      }}
    >
      <div className={css.sectionContent}>
        {(props.data.header ||
          props.data.text) && (
            <div className={css.allCategoriesContainer}>
              <AllCategoriesBox
                text={props.data.text}
                textAlign={props.data?.titleAlignment ?? 'start'}
                color={props.data?.style?.color || '#632dff'}
                title={props.data.header}
              />
            </div>
          )}

        {props.data?.textPictures.length > 0 && (
          <div
            className={css.categoriesLinksContainer}
            style={{
              justifyContent: props.data?.childAlignment ?? 'flex-start',
            }}
          >
            <div className={css.categoryCardsContainer}>
              {props.data.textPictures?.map(category => {
                return category ? (
                  <Fragment key={category._key}>
                    <CategoryCard
                      color={props.data.style.color}
                      image={category.image}
                      url={category.url}
                      header={category.header}
                      text={category.text}
                      subheader={category.subheader}
                      isReverse={category.isReverse}
                      cta={category.cta}
                    />
                  </Fragment>
                ) : null;
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SectionTextImages;
