import { IconSpinner, ListingCard, PrimaryButton } from '../../../components';
import css from './Structure.module.scss';
import { useSelector } from 'react-redux';
import { getListingsById } from '../../../ducks/marketplaceData.duck';
import BlockContent from '@sanity/block-content-to-react';
import SectionHeader from '../SectionHeader/SectionHeader';

import SectionMobileApp from '../SectionMobileApp/SectionMobileApp';
import SectionFeaturedSquare from '../SectionFeaturedSquare/SectionFeaturedSquare';
import SectionFeaturedProductsPolaroids from '../SectionFeaturedProductsPolaroids/SectionFeaturedProductsPolaroids';
import SectionSearchQuery from '../SectionCardWithLink/SectionSearchQuery';
import SectionTextImages from '../SectionTextImages/SectionTextImages';

interface IFandomCardProps {
  caption: string;
  text: string;
  btnText: string;
  btnUrl: string;
  cmsData: any;
  fandomListings: any;
}

const Structure = (props: IFandomCardProps) => {


  return  props.cmsData? (
    <> 
      {props.cmsData.map((pagePart, index) => {
        if (pagePart._type == 'header') {
          return (

            <div key={'str_part_'+index} className={css.heroContainer}>


              <SectionHeader data={pagePart} /> 
            </div>
          );
        }
        
        if (pagePart._type == 'featured_type_2') {
          return (

             <SectionSearchQuery key={'str_part_'+index} data={pagePart} />


          );
        }

        if (pagePart._type == 'featured_type_1') {
          return (

          <SectionFeaturedSquare  key={'str_part_'+index} data={pagePart} fandomListings={props.fandomListings} index={index}/>

          );
        }

        if (pagePart._type == 'featured_type_polaroid') {
          return (

          <SectionFeaturedProductsPolaroids key={'str_part_'+index} data={pagePart} fandomListings={props.fandomListings} index={index}/>



          );
        }
        
        if (pagePart._type == 'mobile_app_section') {
          return (

            <SectionMobileApp key={'str_part_'+index} data={pagePart} />


          );
        }

        if (pagePart._type == 'text_picture_section') {
          return (

          <SectionTextImages key={'str_part_'+index} data={pagePart} />


          );
        }
      })}
    </>
  ): <></>;
};


export default Structure;
